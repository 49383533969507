/* eslint-disable no-process-env */
const OPTIMIZE_DOMAIN = [
  'assets-dev.lyearn.com',
  'assets.lyearn.com',
  'images.unsplash.com',
  'lyearn-classroom-resources.s3.ap-south-1.amazonaws.com',
  'files.lyearn.com',
  'dev-files.lyearn.com',
  'stage-files.lyearn.com',
];

const NATIVE_IMAGE_SIZES = [96, 128, 256, 384, 640, 750, 828, 1080];

if (OPTIMIZE_DOMAIN.length !== 7) {
  throw new Error('Need to update domain list in backend lambda fn');
}

const shouldOptimize = (src) =>
  process.env.NEXT_PUBLIC_IMAGE_SERVER &&
  src &&
  OPTIMIZE_DOMAIN.find((domain) => src.startsWith(`https://${domain}`));

// used in next.config.js thus cjs file
module.exports = { OPTIMIZE_DOMAIN, shouldOptimize, NATIVE_IMAGE_SIZES };
