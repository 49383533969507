import { createContext, useContext } from 'react';
import { CSPDirectives } from 'csp-header/src/types';
import { ThemeConfigType } from 'theme';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import {
  BackgroundLayoutTypeEnum,
  JobPurposeEnum,
  LivestreamProviderEnum,
  PageConfigType,
  TagAvailableOnEnum,
  VisibilitySettingsInput,
} from '@/types/schema';

import { AllowedSessionTypes, DEFAULT_CONFIG, WebinarAllowedMicroApps } from './const';

type AuthField = { type: string; id: string; placeholder: string };
export type AuthConfigMethodMeta = { required?: AuthField[] } | undefined;

export type SSOStrategyType = {
  provider: string;
  allowUsername?: boolean;
  icon?: string;
  signInLabel: string;
  signUpLabel: string;
  type?: string;
  webStrategyUrl: string;
  samlConfigs?: { [key: string]: unknown }[];
  meta?: string;
  hidden?: boolean;
};

export type BackgroundType = {
  imageUrl?: string;
  layout: BackgroundLayoutTypeEnum;
};

export type AppConfig = {
  site: {
    defaultCommunitySettings?: { communityId?: string };
    liveChat?: { id: string; hashKey: string };
    favicon?: string;
    name: string;
    icon?: string;
    logoUrl?: string;
    logo?: Array<{ resolutions: Array<{ type: string; url: string }>; type: string; url: string }>;
    app?: 'enterprise' | 'classroom';
    appUrl?: string;
    appUrlTarget?: string;
    loginUrl?: string;
    locale?: string;
    importJobTemplates?: Record<JobPurposeEnum, string>; //to be removed later after new api from backend
    linkedinInfo?: {
      orgId?: string;
      orgName?: string;
    };
    isFederationIdEnabled?: boolean;
    defaultContentVisibilitySettings: VisibilitySettingsInput;
    enableURQLOfflineCache?: boolean;
    enableIntegrations: boolean;
    certificateSharingSettings: {
      disableLinkedInSharing: boolean;
      disableSocialSharing: boolean;
    };
    enabledConvertibleImport: boolean;
    enablePageEditorPowerUp: boolean;
    allowMemoryCookie?: boolean;
    subWorkspacesEnabled: boolean;
    showSubWorkspaceLogin?: boolean;
    defaultLiveStreamProvider?: LivestreamProviderEnum;
    webinarAllowedMicroApps?: WebinarAllowedMicroApps;
    disabledMultipartUpload?: boolean;
    disabledUnsplash?: boolean;
    enableDomainChange: boolean;
    isTaskEnabled: boolean;
    allowedSessionTypes?: AllowedSessionTypes;
    isMyProfileBetaEnabled?: boolean;
    canConfigureAssignmentNotification?: boolean;
    canManageNotifications: boolean;
    canManageOrgRole: boolean;
    canManageAcademySetting: boolean;
    canManageAuthSetting: boolean;
    enableAssignmentRestriction: boolean;
    enforceCSP?: boolean;
    academySetupCompleted?: boolean;
  };
  authConfig: {
    signUpAllowed?: boolean;
    authPageUrl?: string;
    strategyMethods?: SSOStrategyType[];
    title?: string;
    background?: BackgroundType;
    strategyMethodsListing?: string[];
  };
  csp?: Partial<CSPDirectives>;
  theme?: ThemeConfigType & {
    fontVariant?: {
      primary?: {
        name: string;
      };
      secondary?: {
        name: string;
      };
    };
  };
  isLoggingEnabled?: boolean;
  ui: {
    allowLearnerReview?: boolean;
    showHomeHeader?: boolean;
    showRoleManager: boolean;
    showGettingStarted?: boolean;
    showUserNotification: boolean;
    showBookmark: boolean;
    isIFrameBackAllowed?: boolean;
    isDarkModeAllowed?: boolean;
    showCustomReporting: boolean;
    showImportCreateBtn: boolean;
    tagsNotAvailableOn?: TagAvailableOnEnum[];
    retoolAcademiesManagementUrl?: string;
    disableUserSelection?: boolean;
    showGroupManager: boolean;
    showStaff: boolean;
    showMyReport?: boolean;
    disableCopyLinks?: boolean;
    showReportExport: boolean;
  };
  workspaceId?: string;
  subWorkspaces?: { id: string; name: string }[];
  systemUserId?: string;
  pageConfigs?: PageConfigType;
};

export type { WebinarAllowedMicroApps } from './const';
export const AppConfigContext = createContext<AppConfig | null>(null);
export const useAppConfig = () => {
  const context = useContext(AppConfigContext);

  if (!context) {
    ErrorReporter.error(
      new Error(
        JSON.stringify({
          message: 'App context is null',
          context,
        }),
      ),
    );

    return DEFAULT_CONFIG;
  }

  return context;
};
