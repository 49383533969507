import useMedia from '../useMedia';
import { BREAKPOINT_QUERIES } from './const';
import { ScreenTypeEnum, UseScreenReturnType } from './types';

export { BREAKPOINT_QUERIES };
declare global {
  interface ScreenOrientation {
    lock(orientation: 'portrait' | 'landscape'): Promise<void>;
  }
  interface Window {
    AndroidWebAppInterface?: {
      enterFullscreen: () => void;
      exitFullscreen: () => void;
    };
    iOSWebAppInterface?: {
      enterFullscreen: () => void;
      lockOrientationLandscape: () => void;
      lockOrientationPortrait: () => void;
    };
    webkit?: {
      messageHandlers: any;
    };
    previewDevice: 'desktop' | 'mobile';
  }
}
export default function useScreen(): UseScreenReturnType {
  const screen = useMedia(
    [BREAKPOINT_QUERIES.Desktop, BREAKPOINT_QUERIES.Tablet, BREAKPOINT_QUERIES.Mobile],
    [ScreenTypeEnum.Desktop, ScreenTypeEnum.Tablet, ScreenTypeEnum.Mobile],
    ScreenTypeEnum.Desktop,
  );

  if (typeof window === 'undefined') {
    // Return mock data if useScreen is called from an environment that doesn't have the window object (i.e., not browser)
    // This is required for SSR
    return {
      screen: ScreenTypeEnum.Desktop,
      isDesktop: true,
      isTablet: false,
      isMobile: false,
      isLandScapeMode: false,
      rotateScreenToLandscape: () => {},
      rotateScreenToPortrait: () => {},
    };
  }

  const previewDevice = window.previewDevice || undefined; //for widgetEditorPreview

  if (previewDevice === 'mobile') {
    return {
      screen: ScreenTypeEnum.Mobile,
      isDesktop: false,
      isTablet: false,
      isMobile: true,
      isLandScapeMode: false,
      rotateScreenToLandscape: () => {},
      rotateScreenToPortrait: () => {},
    };
  }

  const isDesktop = screen === ScreenTypeEnum.Desktop;
  const isTablet = screen === ScreenTypeEnum.Tablet;
  const isMobile = screen === ScreenTypeEnum.Mobile;
  const orientation = window.screen.orientation;
  const isLandScapeMode =
    orientation &&
    (orientation.type == 'landscape-primary' || orientation.type == 'landscape-secondary');
  const rotateScreenToLandscape = () => {
    if (orientation && orientation.lock) {
      try {
        orientation.lock('landscape');
      } catch (error) {
        console.error('Error locking orientation:', error);
      }
    }
    if (window?.AndroidWebAppInterface?.enterFullscreen) {
      window.AndroidWebAppInterface.enterFullscreen();
    } else if (window?.webkit?.messageHandlers?.iOSWebAppInterface) {
      window.webkit.messageHandlers.iOSWebAppInterface.postMessage('enterFullscreen');
    } else {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen().catch((err) => {
          console.error('Error attempting to enable fullscreen mode:', err);
        });
      }
    }
  };
  const rotateScreenToPortrait = () => {
    if (orientation && orientation.lock) {
      try {
        orientation.lock('portrait');
      } catch (error) {
        console.error('Error locking orientation:', error);
      }
    }
    if (window?.AndroidWebAppInterface?.exitFullscreen) {
      window.AndroidWebAppInterface.exitFullscreen();
    } else if (window?.webkit?.messageHandlers?.iOSWebAppInterface) {
      window.webkit.messageHandlers.iOSWebAppInterface.postMessage('exitFullscreen');
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen().catch((err) => {
          console.error('Error attempting to exit fullscreen mode:', err);
        });
      }
    }
  };

  return {
    screen,
    isDesktop,
    isTablet,
    isMobile,
    isLandScapeMode,
    rotateScreenToLandscape,
    rotateScreenToPortrait,
  };
}
