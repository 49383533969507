import React from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import getEnabledItems from '@lyearn/core/utils/array/getEnabledItems';
import { useTranslation } from '@lyearn/i18n';
import {
  BookmarkOutline,
  ClipboardOutline,
  CompassOutline,
  EditOutline,
  Globe,
  GridOutline,
  LogOutOutline,
  PeopleOutline,
  PersonOutline,
  PieChartOutline,
  RepeatFill,
  SurveyOutline,
} from '@lyearn/icons';
import { ActionMenuItemType } from '@lyearn/molecules';
import { cn } from '@lyearn/style';
import { Link } from '@/components/Link';
import UserInfoLayout from '@/components/User/layouts/UserInfoLayout';
import currentUser from '@/helper/currentUser';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import { useAppConfig } from '@/hooks/useAppConfig';
import { LearnerActionPlanTabs } from '@/modules/action-plan/views/MyActionPlans/types';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import routes from '@/pages/corporate/routes';
import { useAdminRoute } from '@/pages/corporate/routes/hooks/useAdminRoutes';

import { LayoutType, UserInfoLayoutProps } from '../User/layouts/UserInfoLayout/types';
import { logoutItemStyles } from './styles';

export enum ActionTypes {
  MyBookmarks = 'MY_BOOKMARKS',
  EditProfile = 'EDIT_PROFILE',
  Logout = 'LOGOUT',
  GoToAdmin = 'GO_TO_ADMIN',
  GoToLearner = 'GO_TO_LEARNER',
  GoToMyTeam = 'GO_TO_MY_TEAM',
  GoToMyProfile = 'GO_TO_MY_PROFILE',
  GoToMyProfileBeta = 'GO_TO_MY_PROFILE_BETA',
  GoToMyReports = 'GO_TO_MY_REPORTS',
  GoToMySurveys = 'GO_TO_MY_SURVEYS',
  ToReview = 'TO_REVIEW',
  GoToActionPlans = 'GO_TO_ACTION_PLANS',
  Language = 'LANGUAGE',
  GoToJourneys = 'GO_TO_JOURNEYS',
}

const USER_INFO_OVERRIDES: UserInfoLayoutProps['overrides'] = {
  imageSize: 40,
  header: {
    typography: 'heading-xs',
    className: 'truncate flex-1 pe-20',
  },
  description: {
    typography: 'body-short-02',
    color: 'text-text-secondary',
    className: 'truncate flex-1 pe-20',
  },
};

const MOBILE_USER_INFO_OVERRIDES: UserInfoLayoutProps['overrides'] = {
  ...USER_INFO_OVERRIDES,
  header: {
    ...USER_INFO_OVERRIDES.header,
    typography: 'heading-s',
  },
};

export const useMenuItems = (showUserProfile: boolean) => {
  const { t } = useTranslation();
  const {
    site: { isMyProfileBetaEnabled },
    ui: { allowLearnerReview, showMyReport },
  } = useAppConfig();
  const {
    [FeatureFlags.EnableReports]: showReports,
    [FeatureFlags.EnableUserSurvey]: enableUserSurvey,
    [FeatureFlags.EnableMultiLanguage]: enableMultiLanguage,
    [FeatureFlags.DefaultRestricted]: isDefaultRestricted,
    [FeatureFlags.EnableEmployeeJourney]: enableEmployeeJourney,
    [FeatureFlags.EnableLearnModule]: isLearnModuleEnabled,
    [FeatureFlags.EnablePerformModule]: isPerformModuleEnabled,
    [FeatureFlags.EnableEngageModule]: isEngageModuleEnabled,
  } = useFeatureFlags();
  const user = useUserContext();
  const isAdminRoute = useAdminRoute();

  const isStaff = Boolean(user.isStaff);
  const isManager = Boolean(user.isManager);

  const { isMobile } = useScreen();

  return React.useMemo(() => {
    const iconSizeClassname = cn(isMobile ? 'w-24 h-24' : 'w-20 h-20', 'me-12');

    const menuItems: (ActionMenuItemType & { enabled?: boolean })[] = [
      {
        type: 'divider',
        classes: { root: cn('p-0 pb-8', { ' -mx-8': isMobile }) },
        enabled: true,
      },
      {
        id: ActionTypes.EditProfile,
        label: t('Common:EditProfile'),
        StartIcon: (props) => <EditOutline {...props} className={iconSizeClassname} />,
        enabled: showUserProfile && isMobile,
      },
      {
        id: ActionTypes.GoToMyProfile,
        label: t('Common:GoToMyProfile'),
        as: Link,
        StartIcon: (props) => <PersonOutline {...props} className={iconSizeClassname} />,
        asProps: { to: routes.LearnerProfile(user.id) },
        enabled: true,
      },
      {
        id: ActionTypes.Language,
        label: t('Common:Language'),
        StartIcon: (props) => <Globe {...props} className={iconSizeClassname} />,
        enabled: isMobile && enableMultiLanguage,
      },
      {
        id: ActionTypes.GoToMyProfileBeta,
        label: t('Common:MyProfileBeta'),
        as: Link,
        StartIcon: (props) => <PersonOutline {...props} className={iconSizeClassname} />,
        asProps: { to: routes.UserProfile(user.id) },
        enabled: !isMobile && isMyProfileBetaEnabled,
      },
      {
        id: ActionTypes.MyBookmarks,
        label: t('Common:Routes.MyBookmarks'),
        StartIcon: (props) => <BookmarkOutline {...props} className={iconSizeClassname} />,
        as: Link,
        asProps: { to: routes.Bookmarks() },
        enabled: !showUserProfile || isMobile,
      },
      {
        id: ActionTypes.GoToMyTeam,
        label: t('Common:GoToMyTeam'),
        as: Link,
        asProps: { to: routes.TeamDashboard() },
        StartIcon: (props) => <PeopleOutline {...props} className={iconSizeClassname} />,
        enabled: !isMobile && isManager,
      },
      {
        id: ActionTypes.GoToMyReports,
        label: t('Common:MyReports'),
        as: Link,
        asProps: { to: routes.LearnerReports() },
        StartIcon: (props) => <PieChartOutline {...props} className={iconSizeClassname} />,
        enabled: !isMobile && (showReports || showMyReport),
      },
      {
        id: ActionTypes.GoToMySurveys,
        label: t('Common:MySurveys'),
        as: Link,
        asProps: { to: routes.MySurveys() },
        StartIcon: (props) => <SurveyOutline {...props} className={iconSizeClassname} />,
        enabled: isEngageModuleEnabled && !isMobile && enableUserSurvey,
      },
      {
        id: ActionTypes.GoToActionPlans,
        label: t('Common:ActionPlans'),
        as: Link,
        asProps: { to: routes.LearnerActionPlans(LearnerActionPlanTabs.MyActionPlans) },
        StartIcon: (props) => <GridOutline {...props} className={iconSizeClassname} />,
        enabled: (isEngageModuleEnabled || isPerformModuleEnabled) && !isMobile && enableUserSurvey,
      },
      {
        id: ActionTypes.ToReview,
        label: t('Common:ToReview'),
        as: Link,
        asProps: { to: routes.ToReview() },
        StartIcon: (props) => <ClipboardOutline {...props} className={iconSizeClassname} />,
        enabled: isPerformModuleEnabled && allowLearnerReview && !isMobile,
      },
      {
        id: ActionTypes.GoToJourneys,
        label: t('Common:Journeys'),
        StartIcon: (props) => <CompassOutline {...props} className={iconSizeClassname} />,
        enabled: isLearnModuleEnabled && enableEmployeeJourney,
      },
      {
        id: ActionTypes.GoToAdmin,
        label: t('Common:GoToAdmin'),
        StartIcon: (props) => <RepeatFill {...props} className={iconSizeClassname} />,
        enabled: !isMobile && isStaff && !isAdminRoute && !currentUser.isMasquerading(),
      },
      {
        id: ActionTypes.GoToLearner,
        label: t('Common:GoToLearner'),
        StartIcon: (props) => <RepeatFill {...props} className={iconSizeClassname} />,
        enabled: !isMobile && isStaff && isAdminRoute,
      },
      {
        id: ActionTypes.Logout,
        label: t('Common:Signout'),
        className: logoutItemStyles,
        appearance: 'danger',
        StartIcon: (props) => <LogOutOutline {...props} className={iconSizeClassname} />,
        enabled: showUserProfile && !(isDefaultRestricted && isMobile),
      },
    ];

    return {
      userInfo: (
        <UserInfoLayout
          className="native:mt-8 flex-row-reverse items-center justify-between px-12 pb-12 xs:mt-32 xs:px-24 xs:pb-20"
          layout={LayoutType.AvatarAndEmail}
          overrides={isMobile ? MOBILE_USER_INFO_OVERRIDES : USER_INFO_OVERRIDES}
          user={user}
        />
      ),
      items: getEnabledItems(menuItems).map((item) => {
        const { enabled, ...restItem } = item;

        return restItem as ActionMenuItemType;
      }),
    };
  }, [
    allowLearnerReview,
    enableEmployeeJourney,
    enableMultiLanguage,
    enableUserSurvey,
    isAdminRoute,
    isDefaultRestricted,
    isManager,
    isMobile,
    isMyProfileBetaEnabled,
    isStaff,
    showMyReport,
    showReports,
    showUserProfile,
    t,
    user,
  ]);
};
