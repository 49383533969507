import { relayPagination } from '@urql/exchange-graphcache/extras';

import { userDetailsResolver } from '@/components/User/layouts/UserInfoTooltip/graphql/resolvers/userDetailsResolver';
import { okrDetailsResolver } from '@/modules/goals/modules/okr/graphql/resolvers/okrDetailsResolver';
import { metricDetailsResolver } from '@/modules/org-hierarchy/modules/tracks/views/TrackDetails/graphql/resolvers/metricDetails';
import { orgRoleDetailsResolver } from '@/modules/org-hierarchy/modules/tracks/views/TrackDetails/graphql/resolvers/orgRoleDetailsResolver';
import { skillDetailsResolver } from '@/modules/org-hierarchy/modules/tracks/views/TrackDetails/graphql/resolvers/skillDetailsResolver';
import { taskDetailsResolver } from '@/modules/tasks/views/TasksDashboard/graphql/resolver/taskDetailsResolver';

const resolvers = {
  ContentsWidgetType: {
    contents: relayPagination(),
  },
  ContentPerformanceWidgetType: {
    contents: relayPagination(),
  },
  TrendingContentsWidgetType: {
    contents: relayPagination(),
  },
  ContinueLearningWidgetType: {
    contents: relayPagination(),
  },
  CompletedLearningsWidgetType: {
    contents: relayPagination(),
  },
  PerformanceMetricsWidgetType: {
    contents: relayPagination(),
  },
  GlobalSearchWidgetType: {
    contents: relayPagination(),
  },
  GlobalSearchPayload: {
    lessons: relayPagination(),
  },
  Query: {
    resources: relayPagination(),
    orgRoles: relayPagination(),
    departments: relayPagination(),
    events: relayPagination(),
    databaseData: relayPagination(),
    surveyResponses: relayPagination(),
    posts: relayPagination(),
    scorecards: relayPagination(),
    contents: relayPagination(),
    users: relayPagination(),
    skills: relayPagination(),
    skillMatrices: relayPagination(),
    questions: relayPagination(),
    userSearchActivities: relayPagination(),
    paginatedBookmarks: relayPagination(),
    groups: relayPagination(),
    learnerEvents: relayPagination(),
    tags: relayPagination(),
    assignments: relayPagination(),
    channelParticipants: relayPagination(),
    eventAttendeesV2: relayPagination(),
    staffRoles: relayPagination(),
    reports: relayPagination(),
    userNotifications: relayPagination(),
    contentVersions: relayPagination(),
    certificates: relayPagination(),
    emailDigests: relayPagination(),
    exerciseSubmissionsForReviewer: relayPagination(),
    okrCycles: relayPagination(),
    okrs: relayPagination(),
    auditMetadatas: relayPagination(),
    marketplaceContents: relayPagination(),
    commonProviders: relayPagination(),
    reviewCycles: relayPagination(),
    jiraIssues: relayPagination(),
    peerFeedbacks: relayPagination(),
    actionItems: relayPagination(),
    praises: relayPagination(),
    paginatedGroupedActionItems: relayPagination(),
    tagOptions: relayPagination(),
    metrics: relayPagination(),
    activityMetrics: relayPagination(),
    userActivityMetrics: relayPagination(),
    okr: okrDetailsResolver,
    actionItem: taskDetailsResolver,
    user: userDetailsResolver,
    metric: metricDetailsResolver,
    skill: skillDetailsResolver,
    orgRole: orgRoleDetailsResolver,
    certificateTemplates: relayPagination(),
    userSurveys: relayPagination(),
    themes: relayPagination(),
    getMissingParticipantsDataCheckCounts: relayPagination(),
    actionPlans: relayPagination(),
    sharedViews: relayPagination(),
    userSurveysToSubmit: relayPagination(),
    getRecommendedContentsForSkillIds: relayPagination(),
    assignedUsers: relayPagination(),
    parentContents: relayPagination(),
    widget: relayPagination(),
    journeys: relayPagination(),
    journeyPerformances: relayPagination(),
    journeySteps: relayPagination(),
    announcements: relayPagination(),
    userAnnouncements: relayPagination(),
  },
};

export default resolvers;
