import React from 'react';

import platform from '@lyearn/core/utils/Platform';
import { useTranslation } from '@lyearn/i18n';
import { Logo } from '@lyearn/icons';
import { cn } from '@lyearn/style';
import { Box } from '@lyearn/ui';
import { Image } from '@/components/Image';
import { Link } from '@/components/Link';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import { useAppConfig } from '@/hooks/useAppConfig';

import { logoStyles } from './styles';
import type { LeftNodeProps } from './types';

function DefaultLeftNode(props: LeftNodeProps) {
  const { t } = useTranslation();
  const { textColor, logoPreviewUrl } = props;
  const {
    site: { logoUrl, appUrl, appUrlTarget, name },
  } = useAppConfig();
  const { [FeatureFlags.ShowUserProfile]: showUserProfile } = useFeatureFlags();
  const { [FeatureFlags.DefaultRestricted]: isDefaultRestricted } = useFeatureFlags();

  if (!showUserProfile) return null;

  const LogoImage = logoUrl ? (
    <Image
      useImg
      alt={t('Common:LogoOfImage', { imageAlt: name })}
      className={isDefaultRestricted ? 'h-32' : 'h-24'}
      resizeMode="contain"
      src={logoPreviewUrl || logoUrl}
      // need style prop to work in native
      style={
        platform.OS !== 'web' ? { height: 24, width: 100, alignItems: 'flex-start' } : undefined
      }
    />
  ) : (
    <Logo
      className={cn('h-24 transition duration-300 ease-in-out', textColor, logoStyles)}
      fill="currentColor"
    />
  );

  const LogoComponent = appUrl ? (
    <a
      aria-label={t('Common:GoToHome', { appName: name })}
      href={appUrl}
      rel="noreferrer"
      target={appUrlTarget}>
      {LogoImage}
    </a>
  ) : (
    <Link aria-label={t('Common:GoToHome', { appName: name })} to="/">
      {LogoImage}
    </Link>
  );

  return (
    <Box aria-label={t('Common:Home')} className="ps-24 xs:ps-24" component="nav">
      {LogoComponent}
    </Box>
  );
}

export default React.memo(DefaultLeftNode);
